import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

const ChildrenFocusBlur = ({ children, onBlur, onFocus, ...props }) => {
  const handleBlur = useCallback(
    ({ currentTarget }) => {
      // Give browser time to focus the next element
      requestAnimationFrame(() => {
        // Check if the new focused element is a child of the original container
        if (!currentTarget.contains(document.activeElement)) {
          onBlur()
        }
      })
    },
    [onBlur]
  )

  const handleFocus = useCallback(() => {
    // Give browser time to focus the next element
    requestAnimationFrame(() => {
      onFocus()
    })
  }, [onFocus])

  return (
    <div {...props} onBlur={handleBlur} onFocus={handleFocus}>
      {children}
    </div>
  )
}

ChildrenFocusBlur.propTypes = {
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
}

ChildrenFocusBlur.defaultProps = {}

export default ChildrenFocusBlur
