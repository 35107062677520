// eslint-disable-next-line import/no-namespace
import * as DSIcons from '@nike/nike-design-system-icons'
import PropTypes from 'prop-types'
import React from 'react'

const DSIcon = ({ type, ...rest }) => {
  const IconComponent = DSIcons[type]
  if (!IconComponent) return null
  return <IconComponent {...rest} />
}

// Filter out non-icon components
export const DSTypes = Object.keys(DSIcons).filter((type) => {
  return (
    type !== 'CheckboxIcon' && type !== 'SuccessIcon' && type !== 'SvgIcon' && type !== 'RadioIcon'
  )
})

DSIcon.propTypes = {
  /** className passed to Icon */
  className: PropTypes.string,
  /** icon type  */
  type: PropTypes.oneOf(DSTypes).isRequired,
  /** Allow to custom webfont size */
  size: PropTypes.oneOf(['s', 'm', 'l']),
}

export default DSIcon
